<template>
  <div id="management">
    <div class="content">
      <div class="container">
        <div class="d-flex space-between respon-menage">
          <p class="tab-setting">บริการทางการแพทย์</p>
          <div>
            <el-button round class="btn-custom-add">
              <router-link to="/management/service">
                <i class="el-icon-plus"></i>เพิ่มบริการทางการแพทย์
              </router-link>
            </el-button>
          </div>
        </div>
         <div class="group-export ">
            <el-button round type="button"
              @click="downLoadDocument()"
              class="btn-custom-export">
              <!-- <router-link to="/management/service"> -->
                <i class="fas fa-download"></i>ดาวน์โหลดเอกสาร
              <!-- </router-link> -->
            </el-button>
          </div>
        <el-table :data="dataService" style="width: 100%" class="custom-table">
          <el-table-column
            type="index"
            label="no"
            width="50"
            align="center"
            :index="(index) => indexMethod(index, page, pageSize)"
          >
          </el-table-column>
          <el-table-column prop="nameTH" label="ชื่อศูนย์บริการ">
          </el-table-column>
          <el-table-column label="เบอร์โทรศัพท์" align="center" width="110">
            <template slot-scope="props">
              <span v-if="typeof props.row.phone == 'string'">{{
                props.row.phone
              }}</span>
              <span v-else-if="props.row.phone == ''">-</span>
            </template>
          </el-table-column>
          <el-table-column label="Address">
            <template slot-scope="address">
              <span>
                {{
                  address.row.address +
                  " ต." +
                  address.row.subDistrict +
                  " อ." +
                  address.row.district +
                  " จ." +
                  address.row.province +
                  " " +
                  address.row.postcode
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="right"
            :width="widthMax < 575.98 ? '90' : '200'"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                class="add-openday"
                @click="
                  $router.push(`/management/service?serviceId=${scope.row._id}`)
                "
                >แก้ไข</el-button
              >
              <el-button
                size="mini"
                type="primary"
                class="del-openday"
                @click="modalDel(scope.row._id)"
                >ลบ</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pd-y-3 text-center">
          <el-pagination
            @current-change="changePage"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page="page"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="Delete"
      :visible.sync="centerDialogVisible"
      width="29%"
      center
      class="custom-modal"
    >
      <span>คุณต้องการลบศูนย์บริการทางการแพทย์นี้ใช่หรือไม่ ?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">ยกเลิก</el-button>
        <el-button
          size="mini"
          type="primary"
          class="del-openday"
          @click="delService()"
          >ลบ</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios.js";
export default {
  mounted() {
    this.fetchService();
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      callImage: process.env.VUE_APP_BASE_URL_GET,
      widthMax: window.innerWidth,
      centerDialogVisible: false,
      dataService: [],
      page: 1,
      total: 0,
      loading: true,
      dataId: "",
      pageSize: 10,
    };
  },
  methods: {
    downLoadDocument() {
      HTTP.get(`export/data`)
        .then((res) => {
          console.log('res: ',res);
          if (res.data.success) {
            let routeData = this.callImage+"system/" + res.data.filename;
            window.open(routeData);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("downLoadDocument error", e);
        });
    },
    fetchService() {
      HTTP.get(
        `services/${(this.page - 1) * this.pageSize}/${
          this.pageSize
        }/${"all"}/0`
      )
        .then((res) => {
          if (res.data.success) {
            console.log("fetchService", res.data.obj);
            this.dataService = res.data.obj;
            this.total = res.data.count.length;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchService error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    changePage(page) {
      this.page = page;
      this.fetchService();
    },
    modalDel(id) {
      this.centerDialogVisible = true;
      this.dataId = id;
      console.log(id);
    },
    delService() {
      console.log(this.dataId);
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.delete(`service/${this.dataId}`)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data);
            this.$message({
              message: "ลบศูนย์บริการทางการแพทย์สำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.page = 1;
            this.centerDialogVisible = false;
            this.fetchService();
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("Delete Service error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },
};
</script>